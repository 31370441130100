<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ title }}</h1>
      <b-form id="locForm" validated>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcDetails }}
              </h2>
              <ToggleArrow @click="Section5 = !Section5" section="Section4" :status="Section5"></ToggleArrow>
            </header>
            <div v-if="Section5">
              <b-row v-if="isAdd">
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element" :label="`${translations.tcType}:`" label-for="type-select"
                    label-class="form-group-label">
                    <b-form-select :title="translations.tcType" v-model="location.org_type_key"
                      :options="optionsPlacementFacilityTypesTranslated" id="type-select"
                      class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="!isAdd">
                <b-col sm="6" class="font-style-3">
                  <b-row>
                    <b-col sm="6" class="font-style-3">
                      <b-form-group class="form-element" :label="`${translations.tcAssignmentNumber}:`"
                        label-for="assignment-input" label-class="form-group-label">
                        <b-form-input id="assignment-input" :placeholder="translations.tcAssignmentNumber"
                          :title="translations.tcAssignmentNumber" v-model="location.camp_assigned_number">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6" class="font-style-3">
                      <b-form-group class="form-element" :label="`${translations.tcMembersRequired}:`"
                        label-for="members-input" label-class="form-group-label">
                        <b-form-input id="members-input" :placeholder="translations.tcMembersRequired"
                          :title="translations.tcMembersRequired" v-model="location.members_required"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6" class="font-style-3">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element" :label="`${translations.tcWebsite}:`" label-for="url-input"
                        label-class="form-group-label">
                        <b-form-input id="url-input" :title="translations.tcWebsite" :placeholder="translations.tcWebsite"
                          v-model="location.url"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="12" class="font-style-3">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element" :label="`${translations.tcOtherInformation}:`"
                        label-for="note-textarea" label-class="form-group-label">
                        <b-form-textarea id="note-textarea" v-model="location.note"
                          :title="translations.tcOtherInformation" :placeholder="translations.tcOtherInformation" rows="5"
                          max-rows="8"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="!!location.org_type_key">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcLocation }}
              </h2>
              <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
            </header>
            <b-form>
              <div v-if="isFoodBank" style="line-height: 65px">
                <b-form-radio-group id="radios-location-type" v-model="locationTypeSelected" :disabled="no_editing"
                  :options="locationTypeOptions" name="radios-location-type" size="lg"></b-form-radio-group>
              </div>
              <b-form-group class="form-element" v-if="showChurchList">
                <div class="font-style-2">{{ translations.tcSelectChurch || `Select a church` }}</div>
                <div class="g-row">
                  <div class="g-col-1 d-flex">
                    <b-form-select :disabled="no_editing" v-model="selected_church_key" :options="churchesForSelection"
                      @change="handleChurchChange" class="form-control flex-1 g-select">
                    </b-form-select>
                  </div>
                </div>
                <div class="mt-3" v-if="!!selected_church_address">
                  <ServiceCard :i18n="translations.components" :obj="selected_church_address"></ServiceCard>
                </div>
              </b-form-group>
            </b-form>
          </div>
          <div class="body" v-if="!showChurchList">
            <div v-if="Section1">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element" :label="`${translations.tcName}:`" label-for="name-input"
                        label-class="form-group-label">
                        <b-form-input :title="translations.tcName" id="name-input" :placeholder="translations.tcName"
                          v-model="location.org_name" required></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <template v-if="isAdd">
                    <b-row>
                      <b-col sm="12" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcAddressType}:`"
                          label-for="adr-type-select" label-class="form-group-label">
                          <b-form-select :title="translations.tcAddressType" v-model="location.address_type_key"
                            :options="optionsAddressTypesTranslated" id="adr-type-select" class="g-select flex-0 mr-3"
                            :required="!isAdd"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcAddress1}:`"
                          label-for="address1-input" label-class="form-group-label">
                          <b-form-input :title="translations.tcAddress1" id="address1-input"
                            :placeholder="translations.tcAddress1" v-model="location.address_line_1" :required="!isAdd">
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcAddress2}:`"
                          label-for="address2-input" label-class="form-group-label">
                          <b-form-input :title="translations.tcAddress2" id="address2-input"
                            :placeholder="translations.tcAddress2" v-model="location.address_line_2"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcCity}:`" label-for="city-input"
                          label-class="form-group-label">
                          <b-form-input :title="translations.tcCity" id="city-input" :required="!isAdd"
                            :placeholder="translations.tcCity" v-model="location.city"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col sm="5" class="font-style-3" v-if="!nonUS">
                        <b-form-group class="form-element" :label="`${translations.tcState}:`" label-for="state-select"
                          label-class="form-group-label">
                          <b-form-select id="state-select" name="state-select" :title="translations.tcState" v-if="!nonUS"
                            v-model="location.state" :options="optionsStates" class="g-select flex-0 mr-3"
                            :required="!isAdd"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col sm="5" class="font-style-3" v-if="nonUS">
                        <b-form-group class="form-element" :label="`${translations.tcStateOrProvince}:`"
                          label-for="state-input" label-class="form-group-label">
                          <b-form-input :title="translations.tcStateOrProvince" id="state-input"
                            :placeholder="translations.tcStateOrProvince" v-model="location.state"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcPostalCode}:`" label-for="zip-input"
                          label-class="form-group-label">
                          <b-form-input :title="translations.tcPostalCode" id="zip-input"
                            :placeholder="translations.tcPostalCode" v-model="location.zip"
                            :required="location.cnt_key === this.usCountry && !isAdd"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" class="font-style-3">
                        <b-form-group class="form-element" :label="`${translations.tcCountry}:`"
                          label-for="country-select" label-class="form-group-label">
                          <b-form-select :title="translations.tcCountry" v-model="location.cnt_key"
                            :options="optionsCountries" id="country-select" class="g-select flex-0 mr-3 mb-3"
                            :required="!isAdd"></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col sm="6" v-if="!isAdd">
                  <b-form-group id="select-group-1" :label="`${translations.tcLocationImage}:`" label-for="location_image"
                    label-class="form-group-label" class="form-element">
                    <b-form-file id="location_image" style="text-transform: uppercase" ref="fileUploadImg"
                      v-model.lazy="location_image_file" name="location_image" :placeholder="translations.tcLocationImage"
                      :browse-text="translations.tcBrowse" :drop-placeholder="translations.tcDropFileHere" />
                  </b-form-group>
                  <div class="mt-3">
                    <img v-if="!!location.location_image" :src="location.location_image" class="church_image" height="185"
                      width="285" alt="location image" />
                    <camera v-if="!location.location_image"></camera>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="isAdd && !showChurchList && !!location.org_type_key">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcCommunications }}
              </h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="6">
                  <b-form-group class="form-element" :label="`${translations.tcPhone}:`" label-for="phone-input"
                    label-class="form-group-label">
                    <b-form-input id="phone-input" :title="translations.tcPhone" :placeholder="translations.tcPhone"
                      v-model="location.phone_number"></b-form-input>
                  </b-form-group>
                  <b-form-group class="form-element" :label="`${translations.tcEmail}:`" label-for="email-input"
                    label-class="form-group-label">
                    <b-form-input id="email-input" :title="translations.tcEmail" :placeholder="translations.tcEmail"
                      v-model="location.email"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary"
                v-if="this.userSelectedLocationKey === null || this.userSelectedLocationKey === ''"
                @click="handleSaveClick(true)" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
                  translations.tcSaveAndAddContact
                }}</b-button>
              <b-button variant="primary" @click="handleSaveClick(false)" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSave }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-location',
  mixins: [numericMixin, translationMixin],
  data() {
    return {
      translations: {},
      no_editing: false,
      first_time: true,
      Section1: true,
      Section2: true,
      Section3: true,
      Section4: true,
      Section5: true,
      selected_church_key: null,
      selected_church_address: null,
      usCountry: constantData.USA_country,
      optionsAddressTypesTranslated: [],
      optionsPlacementFacilityTypesTranslated: [],
      locationTypeSelected: 'church',
      locationTypeOptions: [
        { text: 'Church Location', value: 'church' },
        { text: 'Non-Church Location', value: 'non-church' },
      ],
      location: {
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        address_type_key: constantData.address_type_keys.business,
        camp_assigned_number: '',
        camp_org_key: null,
        city: '',
        cnt_key: null,
        contact: {
          email: '',
          first_name: '',
          gender: '',
          gender_key: null,
          key: null,
          last_name: '',
          name: '',
          phone: '',
          relationship: '',
          relationship_key: null,
          title: '',
        },
        email: '',
        gideoncard_display_number: '',
        last_gideon_card_video_showing: '',
        last_gideon_card_video_showing_mtg_key: null,
        last_placement_date: '',
        last_placement_day: '',
        last_placement_month: '',
        last_placement_mtg_coordinator: '',
        last_placement_mtg_coordinator_key: null,
        last_placement_mtg_key: null,
        last_placement_plc_key: null,
        last_placement_start_time: '',
        last_placement_weekday: '',
        last_placement_year: '',
        location_image: '',
        members_required: 0,
        member_participation_type: '',
        member_participation_type_key: null,
        next_placement_date: '',
        next_placement_day: '',
        next_placement_month: '',
        next_placement_mtg_coordinator: '',
        next_placement_mtg_coordinator_key: null,
        next_placement_mtg_key: null,
        next_placement_plc_key: null,
        next_placement_weekday: '',
        next_placement_year: '',
        note: '',
        number_of_rooms: 0,
        org_name: '',
        org_type_key: null,
        org_type_name: '',
        oxt_delete_flag: false,
        phn_cnt_key: null,
        phone_number: '',
        phone_number_formatted: '',
        plf_cmp_org_key: null,
        plf_org_key: null,
        state: '',
        state_key: null,
        url: '',
        where_we_should_be_flag: false,
        zip: '',
      },
      gender_list: [],
      location_detail: {
        camp_assigned_number: null,
        location_url: null,
        loc_org_key: null,
        member_participation_type_key: null,
        members_required: 0,
        note: null,
        org_type_key: null,
        oxt_delete_flag: false,
        plf_cmp_org_key: null,
      },
      location_image_file: null,
    }
  },
  methods: {
    ...mapActions({
      addLocation: 'scriptureDistribution/addLocation',
      addScriptureLocation: 'scriptureDistribution/addScriptureLocation',
      editPlacementItems: 'scriptureDistribution/editPlacementItems',
      getChurchCommunications: 'churchMinistry/getChurchCommunications',
      getSelectionListsForChurchAdd: 'churchMinistry/getSelectionListsForChurchAdd',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      loadLocationTypeOptions: 'scriptureDistribution/loadLocationTypeOptions',
      loadPlacementContactRelationshipTypeOptions: 'scriptureDistribution/loadPlacementContactRelationshipTypeOptions',
      loadVisibleChurches: 'churchMinistry/loadVisibleChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedOrgTypeKey: 'user/setSelectedOrgTypeKey',
      setShowPrimary: 'scriptureDistribution/setShowPrimary',
    }),
    handleStateChange(evt) {
      this.location.state = this.optionsStates.find((ch) => ch.value === evt).code
    },
    async handleCancelClick() {
      this.$router.go(-1)
    },
    async handleChurchChange() {
      await this.getChurchCommunications(this.selected_church_key).then((sc) => {
        let church_address_array = this.churchCommunicationsData.addresses.filter((af) => af.primary_address_flag === 1)
        this.selected_church_address =
          !!church_address_array && church_address_array.length > 0 ? church_address_array[0] : {}
        this.location.org_name = this.churchesForSelection.find((cs) => cs.value === this.selected_church_key).text
      })
    },
    async handleSaveClick(addContact) {
      if (this.validateForm()) {
        // if this is a VBS or church-related food bank
        if (this.showChurchList) {
          this.location_detail.loc_org_key = this.selected_church_key
          this.location_detail.member_participation_type_key = constantData.member_types.find(
            (mt) => mt.text === 'Both'
          ).value
          this.setSelectedOrgTypeKey(this.location.org_type_key)
          this.location_detail.org_type_key = this.location.org_type_key
          this.location_detail.camp_assigned_number = this.location.camp_assigned_number
          this.location_detail.note = this.location.note
          this.location_detail.members_required = this.location.members_required
          this.location_detail.location_url = this.location.url
          this.location_detail.plf_cmp_org_key =
            !!this.officerToolbarSelected.camp && this.officerToolbarSelected.camp !== this.userLogin.campkey
              ? this.officerToolbarSelected.camp
              : this.userLogin.campkey
          await Promise.all([this.setLoadingStatus(true), await this.addScriptureLocation(this.location_detail)]).then(
            (response) => {
              this.postSave(addContact, response)
            }
          )
        } else {
          await (this.location.location_image = this.location_image_file)
          this.location.camp_org_key =
            !!this.officerToolbarSelected.camp && this.officerToolbarSelected.camp !== this.userLogin.campkey
              ? this.officerToolbarSelected.camp
              : this.userLogin.campkey
          let locationPost = this.prepLocation(this.location)
          await Promise.all([this.setLoadingStatus(true), await this.addLocation(locationPost)]).then((response) => {
            this.postSave(addContact, response)
          })
        }
      }
    },
    async postSave(addContact, response) {
      this.setLoadingStatus(false)
      this.$swal({
        icon: response ? 'success' : 'error',
        confirmButtonText: this.translations.tcOk,
        text: response
          ? !!this.userSelectedLocationKey
            ? this.translations.tcLocationEdited // 'The location was successfully edited.'
            : this.translations.tcLocationAdded // 'The location was added.'
          : this.translations.tcErrorLocationNotSaved, // `An error has occurred. The location was not saved.`,
      }).then(() => {
        if (response) {
          if (!!this.selected_church_key) {
            this.setSelectedLocationKey(this.selected_church_key)
          } else {
            this.setSelectedLocationKey(response[1])
            this.setSelectedOrgTypeKey(this.location.org_type_key)
          }
          this.handleRedirect(addContact)
        }
      })
    },
    async handleRedirect(addContact) {
      this.setLoadingStatus(true)
      let pathname = ''
      if (addContact) {
        pathname = 'location-add-contact'
        await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.location.org_type_key })
      } else {
        if (this.location.org_type_key === constantData.funeralHomeType) {
          pathname = 'placement-locations'
        } else {
          // pathname = (this.isVBS || this.isFoodBank) ? 'location-profile' : 'location-details'  // Josh may change his mind.
          pathname = 'location-profile'
        }
      }
      if (this.showChurchList) {
        this.setShowPrimary(false)
      }
      this.$router.push({ name: pathname })
      this.setLoadingStatus(false)
    },
    resetLocalLoc() {
      this.location_detail = {
        camp_assigned_number: null,
        location_url: null,
        loc_org_key: null,
        member_participation_type_key: null,
        members_required: 0,
        note: null,
        org_type_key: null,
        oxt_delete_flag: false,
        plf_cmp_org_key: null,
      }
      this.location = {
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        address_type_key: constantData.address_type_keys.business,
        camp_assigned_number: '',
        camp_org_key: null,
        city: '',
        cnt_key: null,
        contact: {
          email: '',
          first_name: '',
          gender: '',
          gender_key: null,
          key: null,
          last_name: '',
          name: '',
          phone: '',
          relationship: '',
          relationship_key: null,
          title: '',
        },
        email: '',
        gideoncard_display_number: '',
        last_gideon_card_video_showing: '',
        last_gideon_card_video_showing_mtg_key: null,
        last_placement_date: '',
        last_placement_day: '',
        last_placement_month: '',
        last_placement_mtg_coordinator: '',
        last_placement_mtg_coordinator_key: null,
        last_placement_mtg_key: null,
        last_placement_plc_key: null,
        last_placement_start_time: '',
        last_placement_weekday: '',
        last_placement_year: '',
        location_image: '',
        members_required: 0,
        member_participation_type: '',
        member_participation_type_key: null,
        next_placement_date: '',
        next_placement_day: '',
        next_placement_month: '',
        next_placement_mtg_coordinator: '',
        next_placement_mtg_coordinator_key: null,
        next_placement_mtg_key: null,
        next_placement_plc_key: null,
        next_placement_weekday: '',
        next_placement_year: '',
        note: '',
        number_of_rooms: 0,
        org_name: '',
        org_type_key: null,
        org_type_name: '',
        oxt_delete_flag: false,
        phn_cnt_key: null,
        phone_number: '',
        phone_number_formatted: '',
        plf_cmp_org_key: null,
        plf_org_key: null,
        state: '',
        state_key: null,
        url: '',
        where_we_should_be_flag: false,
        zip: '',
      }
    },
    async getLocationForEdit() {
      if (!!this.userSelectedLocationKey && this.isGuid(this.userSelectedLocationKey)) {
        this.location = { ...this.detail }
        this.location.contact = { ...this.detail.contact }
        this.first_time = false
      } else {
        this.location.cnt_key = this.convertedAddressFormOptions.countriesByCamp[0].value
      }
    },
    validateForm() {
      let errorMsg = ''
      let rtn = true
      if (!this.location.org_name) {
        rtn = false
        errorMsg = `${this.translations.tcErrorLocationNameRequired}<br><br>${this.translations.tcCompleteFieldToContinue}`
      }
      if (this.first_time && (!this.location.org_name || !this.location.org_type_key)) {
        rtn = false
        errorMsg = `${this.translations.tcErrorLocationNameTypeRequired}<br><br>${this.translations.tcCompleteFieldToContinue}`
      }
      if (!rtn) {
        this.$swal({
          icon: 'error',
          html: errorMsg,
          confirmButtonText: this.translations.tcOk,
        })
      }
      return rtn
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        this.resetLocalLoc()
        await Promise.all([
          this.loadAddressFormOptions(),
          this.loadVisibleChurches(),
          this.loadLocationTypeOptions(),
          this.loadPlacementContactRelationshipTypeOptions(),
          this.getLocationForEdit(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.no_editing = false
        if (this.showChurchList && !!this.location.plf_org_key) {
          this.selected_church_key = this.location.plf_org_key
          this.no_editing = true
        }
        this.setLoadingStatus(false)
      }
    },
    prepLocation(loc) {
      const ignoreFields = ({ contact, url, ...rest }) => rest
      let preppedLocation = {
        ...ignoreFields(loc),
        location_url: loc.url ? loc.url : null,
        state_postal_code: loc.state + ' ' + loc.zip,
        contact_key: loc.contact.key,
        contact_first_name: loc.contact.first_name ? loc.contact.first_name : null,
        contact_last_name: loc.contact.last_name ? loc.contact.last_name : null,
        contact_name: loc.contact.name,
        contact_title: loc.contact.title ? loc.contact.title : null,
        contact_gender_key: loc.contact.gender_key ? loc.contact.gender_key : null,
        contact_gender: loc.contact.gender ? loc.contact.gender : null,
        contact_relationship_key: loc.contact.relationship_key ? loc.contact.relationship_key : null,
        contact_relationship: loc.contact.relationship,
        contact_email: loc.contact.email ? loc.contact.email : null,
        contact_phone: loc.contact.phone ? loc.contact.phone : null,
      }
      return preppedLocation
    },
    updateDynamicText() {
      this.gender_list[0].text = this.translations.tcMale
      this.gender_list[1].text = this.translations.tcFemale

      // Update dropdowns w/ translated text for Address Types and Placement Facility Types
      this.optionsAddressTypes = this.convertedAddressFormOptions.addressTypes
      this.optionsPlacementFacilityTypes = this.convertedLocationTypeFormOptions.types
      const disabledPlaceholder = this.translations ? this.translations.tcSelectType : 'Select a Type'

      return this.translateDropdownOptions([
        { node: 'address-types', store: 'optionsAddressTypes', disabledPlaceholder },
        { node: 'placement-facility-types', store: 'optionsPlacementFacilityTypes', disabledPlaceholder },
      ])
    },
  },
  async created() {
    this.setLoadingStatus(true)
    this.gender_list = constantData.gender_list
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('common.address-types', 'common.placement-facility-types'),
      this.getComponentTranslations('service-card'),
      await this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[1])
      const translatedText = { ...results[2], common: { ...this.translations.common } }
      this.$set(this.translations, 'components', translatedText)
      this.updateDynamicText()
    })
  },
  computed: {
    ...mapGetters({
      churchesForSelection: 'churchMinistry/churchesForSelection',
      churchCommunicationsData: 'churchMinistry/churchCommunicationsData',
      contactRelationshipTypeFormOptions: 'scriptureDistribution/contactRelationshipTypeFormOptions',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      convertedContactRelationshipTypeFormOptions: 'scriptureDistribution/convertedContactRelationshipTypeFormOptions',
      convertedLocationTypeFormOptions: 'scriptureDistribution/convertedLocationTypeFormOptions',
      detail: 'scriptureDistribution/locationDetail',
      locationTypeFormOptions: 'scriptureDistribution/locationTypeFormOptions',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
    title() {
      return !this.userSelectedLocationKey ? this.translations.tcAddLocation : this.translations.tcEditLocation
    },
    isAdd() {
      return !this.userSelectedLocationKey
    },
    isFoodBank() {
      return this.location.org_type_key === constantData.scripture_distribution_org_types.foodbank
    },
    isVBS() {
      return this.location.org_type_key === constantData.scripture_distribution_org_types.vbs
    },
    showChurchList() {
      return (
        this.location.org_type_key === constantData.scripture_distribution_org_types.vbs ||
        (this.isFoodBank && this.locationTypeSelected === this.locationTypeOptions[0].value)
      )
    },
    optionsStates() {
      return [
        { value: '', text: this.translations.tcState, disabled: true },
        ...this.convertedAddressFormOptions.states_long.map((x) => {
          return {
            text: x.text,
            value: x.code,
          }
        }),
      ]
    },
    optionsCountries() {
      return [
        { value: null, text: this.translations.tcSelectCountry, disabled: true },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    nonUS() {
      return this.location.cnt_key !== constantData.USA_country
    },
    optionsRelationshipTypes() {
      return [
        { value: null, text: this.translations.tcSelectType, disabled: true },
        ...this.convertedContactRelationshipTypeFormOptions.types,
      ]
    },
    optionsGenderList() {
      return [
        { value: null, text: this.translations.tcSelectAGender || 'Select a gender', disabled: true },
        ...this.gender_list,
      ]
    },
  },
  components: {
    camera: camera,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.custom-control-label {
  color: #747474 !important;
}

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
